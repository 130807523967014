import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import LearnAboutBadgeIcon from '../../assets/images/cms/reader-icon.svg';
import ContentPreviewIcon from '../../assets/images/cms/megaphone-icon.svg';
import {ContentType} from '@/utilities/cmsUtilities';

const icons: Record<string, string> = {
  'CONTENT_PREVIEW': ContentPreviewIcon,
  [ContentType.LEARN_ABOUT]: LearnAboutBadgeIcon
};

const titles: Record<string, string> = {
  'CONTENT_PREVIEW': 'Content Preview',
  [ContentType.LEARN_ABOUT]: 'Learn About'
};

const getIcon = (type: string) => {
  return icons[type] || '';
};

const getTitle = (type: string) => {
  return titles[type] || '';
};

@Component({
  name: 'cms-card-badge'
})
export default class CmsCardBadge extends Vue {
  @Prop({ type: String })
  type!: String;

  icon: String = '';
  title: String = '';

  beforeMount() {
    this.icon = getIcon(this.type as string);
    this.title = getTitle(this.type as string);
  }
}
